<template>
  <div class="mx-2">
    <v-row>
      <v-col cols="12">
        <v-card
          color="info"
          class="my-0"
        >
          <v-card-text class="white--text">
            Need assistance? Our contacts below can help
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-for="help in HelpList"
      :key="help.HelpId"
    >
      <v-col cols="12">
        <base-material-card
          dense
          icon="mdi-account-question"
          color="secondary"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light my-3">
              {{ help.name }}
            </div>
          </template>
          <!-- <v-row>
            <v-col cols="12">
              <span class="display-2 font-weight-light mx-3">{{ help.name }}</span>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <span class="mr-2"><v-icon>mdi-email</v-icon></span>
              <span>{{ help.eMail }}</span>
            </v-col>
            <v-col cols="12">
              <span class="mr-2"><v-icon>mdi-phone</v-icon></span>
              <span>{{ help.phoneNumber }}</span>
            </v-col>
            <!--
            <v-col cols='4'>
              <span class="mr-2"><v-icon>mdi-account-group</v-icon></span>
              <span>{{ help.sectionName }}</span>
            </v-col>
            -->
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import helpAPI from '@/services/applicationHelp'

  export default {
    Name: 'helpListing',
    props: {
      type: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        HelpList: [],
      }
    },
    created: function () {
      //  populate help
      helpAPI.getHelpByType(this.type)
        .then(response => {
          this.HelpList = [...this.HelpList, ...response.data]
        })
    },
  }
</script>
